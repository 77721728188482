import { useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedCompanyId } from '../../../store/slices/companiesSlice';
import { useNavigate } from 'react-router-dom';
import { useCompanyUsersRepository } from '../../../common/hooks/useCompanyUsersRepository';
import { useSelectDraftUsers } from '../../../common/hooks/useSelectDraftUsers';
import { CompaniesDataSource } from '../../../CompaniesPage/api/CompaniesDataSource';

const useCompanyUsersMainContentController = () => {
    const [showAddUsersModal, setShowAddUsersModal] = useState(false);
    const navigate = useNavigate();
    const companyId = useAppSelector(selectSelectedCompanyId);
    const { users } = useCompanyUsersRepository();
    const { draftUsers, selectDraftUsersToUpdate } = useSelectDraftUsers();
    const { getCRMConnectionURL } = CompaniesDataSource();

    const onConfirm = () => {
        setShowAddUsersModal(false);
        navigate(`/companies/${companyId}/users/add-users`);
    };

    const handleOnCancel = (userIdToDelete: string) => {
        selectDraftUsersToUpdate(draftUsers.filter((user) => user.crmUserId !== userIdToDelete));
    };

    const connectToCRM = () => {
        if (!companyId) return;
        getCRMConnectionURL(companyId).then((url) => {
            window.location.href = url;
        });
    };

    return { showAddUsersModal, setShowAddUsersModal, onConfirm, users, handleOnCancel, connectToCRM };
};

export { useCompanyUsersMainContentController };

export type useCompanyUsersMainContentControllerType = typeof useCompanyUsersMainContentController;
